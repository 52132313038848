<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group label="Entity">
      <b-form-checkbox
        v-for="option in entityOptions"
        :key="option._id"
        v-model="entityValue"
        :value="option._id"
        name="flavour-3a"
      >
        {{ option.name }}
      </b-form-checkbox>
    </b-form-group>
    <b-form-group label="Criteria">
      <b-form-checkbox
        v-for="option in extraFilterOptions"
        :key="option.code"
        v-model="extraFilterValue"
        :value="option.code"
        name="flavour-3a"
      >
        {{ option.title }}
      </b-form-checkbox>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    BFormCheckbox,
  },
  props: {
    extraFilter: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    entity: {
      type: [Array, null],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: [],
    },
    extraFilterOptions: {
      type: Array,
      required: true,
    },
    entityOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      extraFilterValue: JSON.parse(JSON.stringify(this.extraFilter)) || [],
      entityValue: JSON.parse(JSON.stringify(this.entity)) || [],
    }
  },
  watch: {
    entity: {
      immediate: true,
      handler(val) {
        this.entityValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    extraFilter: {
      immediate: true,
      handler(val) {
        this.extraFilterValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
  },
  methods: {
    applyFilter() {
      this.$emit('update:entity', this.entityValue)
      this.$emit('update:extraFilter', this.extraFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      this.extraFilterValue = []
      this.entityValue = []
      this.$emit('update:entity', this.entityValue)
      this.$emit('update:extraFilter', this.extraFilterValue)

      // this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
