<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="create-edit-form">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Select Vendor to Evaluate
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-card
      header-tag="header"
    >
      <template #header>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          class="primary-button-with-background mr-3"
        >
          <feather-icon
            icon="GridIcon"
            class=""
            size="20"
          />
        </b-button>
        <h3 class="align-middle mr-auto">
          <span class="step-class">FIRST STEP</span>
          Choose Evaluation Period
        </h3>
      </template>
      <b-row>
        <b-col cols="md-6">
          <b-form-group
            label="Evaluation Period"
            label-for="h-user-pr"
            label-cols-md="4"
          >
            <b-input-group>
              <flat-pickr
                id="main-date"
                v-model="datePeriod"
                class="form-control"
                placeholder="Select Date Range"
                :config="flatPickrConfig"
                @input="setTimeFrame"
              />
              <b-input-group-append is-text>
                <feather-icon
                  icon="CalendarIcon"
                  class="cursor-pointer"
                  data-toggle
                  size="18"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="action-left pt-50 mr-1">
              <b-form-checkbox
                :checked="selectAllVendorsCheckbox"
                :indeterminate="isSelectAllVendorsCheckboxIndeterminate"
                @change="selectAllCheckboxUpdate"
              >
                Select All
              </b-form-checkbox>
            </div>
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                :class="{'disabled': !datePeriod}"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>
              <b-dropdown
                id="dropdown-form-all-vendor"
                ref="filter_dropdown_all_vendor"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
                :disabled="!datePeriod"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <vendor-filter
                  :extra-filter.sync="extraFilter"
                  :extra-filter-options="extraFilterOptions"
                  :entity.sync="entity"
                  :entity-options="businessEntityOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

        <div v-if="entity.length || extraFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="extraVal in extraFilter"
                  :key="extraVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromExtraFilter(extraVal)"
                  >
                    <span class="text-capitalize">{{ extraVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="entityVal in entity"
                  :key="entityVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromEntityFilter(entityVal)"
                  >
                    <span class="text-capitalize">{{ resolveEntityName(entityVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refAllVendorsTable"
        class="position-relative event_list"
        :items="fetchVendors"
        :current-page="currentPage"
        :per-page="0"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nothing in here yet"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #empty="scope">
          <b-row v-if="emptyAfterDate">
            <b-col>
              <div class="empty_block vendor_empty">
                <h4>Sorry, we couldn't find any results.</h4>
                <span class="align-middle">Please choose another period</span>
              </div>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <div class="empty_block">
                <h4>{{ scope.emptyText }}</h4>
                <span class="align-middle">Choose period above to start</span>
              </div>
            </b-col>
          </b-row>
        </template>
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-form-checkbox
              :checked="selectedVendors.includes(data.item._id)"
              @change="toggleSelectedVendors(data.item._id)"
              @click.native.stop
            >
              <b-button
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="align-middle detail-view-id not-button"
              >
                {{ data.item.stringID }}
              </b-button>
            </b-form-checkbox>
          </div>
        </template>

        <template #cell(companyName)="data">
          <div class="text-nowrap text-bold-black">
            <span class="">{{ data.item.companyName }}</span>
          </div>
        </template>

        <template #cell(approvedDate)="data">
          <div class="text-nowrap">
            <span v-if="data.item.approvedDate">{{ dateFormat(data.item.approvedDate) }}</span>
            <span v-else-if="data.item.signatories && data.item.signatories.length">{{ data.item.signatories[data.item.signatories.length - 1].date ? dateFormat(data.item.signatories[data.item.signatories.length - 1].date) : '' }}</span>
          </div>
        </template>

        <template #cell(lastOrder)="data">
          <div class="text-nowrap">
            <div v-if="canViewThisAction('show', 'PurchaseRequest')">
              <b-button
                variant="link"
                class="p-0"
                :to="{ name: 'purchasing-purchase-request-show', params: { id: data.item.lastOrder ? data.item.lastOrder._id : 0 } }"
              >
                {{ data.item.lastOrder ? data.item.lastOrder.caseID : '' }}
              </b-button>
            </div>
            <div v-else>
              <span>{{ data.item.lastOrder ? data.item.lastOrder.caseID : '' }}</span>
            </div>
          </div>
        </template>

        <template #cell(lastOrderAmount)="data">
          <!-- <span>{{ resolveSpentAmount(data.item._id) }}</span> -->
          <span>{{ numberFormat(data.item.lastOrderAmount) }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveVendorStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>
      </b-table>
    </b-card>

    <!-- <div
      v-if="totalVendors > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalVendors"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div> -->

    <b-nav class="wrap-border save-nav">
      <li
        class="nav-item ml-auto"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          @click="confirmBeforeCancel()"
        >
          Cancel
        </b-button>
      </li>
      <li
        class="nav-item mr-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="success"
          :disabled="!selectedVendors.length"
          @click="proceedToEvaluationForm()"
        >
          <b-img
            left
            height="16"
            :src="require('@/assets/images/icons/calculator.png')"
            alt="Left image"
            class="mr-50"
          />
          <span class="align-middle">Evaluate Selected Vendors</span>
        </b-button>
      </li>
    </b-nav>
  </div>
</template>

<script>
import {
  BCard, BRow, BButton, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BFormCheckbox, BFormGroup, BFormTag, BNav, BImg,
} from 'bootstrap-vue'
import { ref, onUnmounted, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import { useUtils as useAclUtils } from '@/libs/acl/custom'
import useSelectedVendorList from './useSelectedVendorList'
import vendorStoreModule from './vendorStoreModule'
import VendorFilter from './VendorFilter.vue'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BImg,
    // BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BFormGroup,
    BFormTag,
    BNav,

    flatPickr,
    VendorFilter,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      datePeriod: '',
      extraFilterOptions: [
        { title: 'S$ 0 to S$ 1,999 Total Spend', code: 'S$ 0 to S$ 1,999 Total Spend' },
        { title: 'S$ 2,000 to S$ 9,999 Total Spend', code: 'S$ 2,000 to S$ 9,999 Total Spend' },
        { title: 'S$ 10,000 to S$ 99,999 Total Spend', code: 'S$ 10,000 to S$ 99,999 Total Spend' },
        { title: 'S$ 100,000 and above Total Spend', code: 'S$ 100,000 and above Total Spend' },
        // { title: 'Active Vendors', code: 'Active Vendors' },
        // { title: 'Inactive Vendors', code: 'Inactive Vendors' },
        // { title: 'Top 75% Only', code: 'Top 75% Only' },
      ],
      businessEntityOptions: [],
      user: store.state.auth.userData,
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
      required,
    }
  },
  watch: {
    timeFrameFilter: {
      immediate: true,
      handler(val) {
        if (val && this.datePeriod === '') {
          this.datePeriod = val
        }
      },
    },
  },

  beforeMount() {
    this.$http.get('purchase/purchase-request/create/extra-options')
      .then(response => {
        this.businessEntityOptions = response.data.businessEntityOptions ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    clearAllFilters() {
      // this.timeFrameFilter = ''
      this.extraFilter = []
      this.entity = []
    },
    hideFilter() {
      this.$refs.filter_dropdown_all_vendor.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_all_vendor.show(true)
    },
    resolveSpentAmount(id) {
      const po = this.purchaseOrders.find(o => o.vendor === id)
      if (po) {
        return `S$ ${this.numberFormat(po.total)}`
      }
      return 'S$ 0.00'
    },
    removeFromExtraFilter(item) {
      const index = this.extraFilter.indexOf(item)
      if (index > -1) {
        this.extraFilter.splice(index, 1)
      }
    },
    removeFromEntityFilter(item) {
      const index = this.entity.indexOf(item)
      if (index > -1) {
        this.entity.splice(index, 1)
      }
    },
    resolveEntityName(val) {
      const ent = this.businessEntityOptions.find(o => o._id === val)
      if (ent) {
        return ent.name || ''
      }
      return ''
    },
    confirmBeforeCancel() {
      this.$swal({
        title: 'Are you sure?',
        html: 'You will have to start over the selection process of vendors again if you leave this page now.',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/icons/warning.png'),
        reverseButtons: true,
        showCancelButton: true,
        cancelButtonText: 'No, resume selecting',
        confirmButtonText: 'Yes, I’m sure',
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-outline-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'purchasing-vendors-index', params: { type: 'all-vendors' } })
          }
        })
    },
    proceedToEvaluationForm() {
      localStorage.setItem('selectedVendorsToEvaluate', JSON.stringify(this.selectedVendors))
      localStorage.setItem('timeFrame', this.timeFrameFilter)
      localStorage.setItem('entity', JSON.stringify(this.entity))
      localStorage.setItem('selectedVendorCount', this.selectedVendors.length)
      localStorage.setItem('totalfilteredVendor', this.totalVendors)
      this.$router.push({ name: 'purchasing-vendors-generate-evaluation-scoring' })
    },
    setTimeFrame() {
      const splitted = this.datePeriod.split(' ')
      if (splitted.length === 3) {
        this.timeFrameFilter = this.datePeriod
      }
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const VENDORS_APP_STORE_MODULE_NAME = 'app-evaluation-vendors'

    // Register module
    if (!store.hasModule(VENDORS_APP_STORE_MODULE_NAME)) store.registerModule(VENDORS_APP_STORE_MODULE_NAME, vendorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VENDORS_APP_STORE_MODULE_NAME)) store.unregisterModule(VENDORS_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchVendors,
      tableColumns,
      perPage,
      currentPage,
      totalVendors,
      purchaseOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAllVendorsTable,

      resolveVendorStatusVariant,
      refetchData,

      toDeletedID,
      vendorData,
      emptyAfterDate,

      // Extra Filters
      extraFilter,
      entity,
      timeFrameFilter,
    } = useSelectedVendorList()

    const selectedVendors = ref([])
    const toggleSelectedVendors = ItemsMasterId => {
      const ItemsMasterIndex = selectedVendors.value.indexOf(ItemsMasterId)

      if (ItemsMasterIndex === -1) selectedVendors.value.push(ItemsMasterId)
      else selectedVendors.value.splice(ItemsMasterIndex, 1)
    }
    const selectAllVendorsCheckbox = computed(() => vendorData.value.length && (vendorData.value.length === selectedVendors.value.length))
    const isSelectAllVendorsCheckboxIndeterminate = computed(() => Boolean(selectedVendors.value.length) && vendorData.value.length !== selectedVendors.value.length)
    const selectAllCheckboxUpdate = val => {
      selectedVendors.value = val ? vendorData.value.map(flow => flow._id) : []
    }

    return {

      // Sidebar

      fetchVendors,
      tableColumns,
      perPage,
      currentPage,
      totalVendors,
      purchaseOrders,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refAllVendorsTable,
      showSearchBar,
      showFilterOptions,

      resolveVendorStatusVariant,
      refetchData,

      toDeletedID,
      vendorData,
      emptyAfterDate,

      // Extra Filters
      extraFilter,
      entity,
      timeFrameFilter,

      // Filter
      canViewThisAction,

      selectAllVendorsCheckbox,
      isSelectAllVendorsCheckboxIndeterminate,
      selectedVendors,
      toggleSelectedVendors,
      selectAllCheckboxUpdate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
  .removeText {
    color:#D91B35;
    cursor: pointer;
  }
  .addOptionText {
    color: #104D9D;
    cursor: pointer;
  }
  .btn-group-toggle [class*=btn-outline-]:not(:last-child) {
    border-right-width: 1px !important;
  }
  .inlineElement {
    display: flex;
  }
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone-custom-title {
    margin-top: 0;
    color: #104D9D;
  }
  .subtitle {
    color: #333333;
  }
  .bookingCounter {
    border-radius: 50%;
    width: 5px;
    height: 5px;
    padding: 2px;

    background: #fff;
    border: 1px solid #666;
    color: #666;
    text-align: center;

    font-size: 10px;
  }

  .vs__dropdown-option--disabled {
    background: #ededed;
    color: #000000;
  }
  .break-page {
    page-break-after: always;
  }
  a.disabled {
    pointer-events: none;
  }
</style>
